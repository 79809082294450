<div class="mb-3">
    <div *ngIf="isReadOnly">
        <label *ngIf="label != null">{{ label }}</label>
        <span class="required-indicator" *ngIf="showRequired">*</span>
        <input readonly="true" type="text" id="{{ id }}" class="form-control" [value]="readOnlyData" />
    </div>
    <div *ngIf="!isReadOnly">
        <label *ngIf="label != null">{{ label }}</label>
        <span class="required-indicator" *ngIf="showRequired">*</span>
        <div class="ui-fluid">
            <p-calendar
                [showTime]="showTime"
                [showIcon]="true"
                [formControl]="control"
                [minDate]="minimumDate"
                dateFormat="dd/mm/yy"
                [readonlyInput]="readonlyInput"
                [selectionMode]="selectionMode"
                [showButtonBar]="showButtonBar"
            ></p-calendar>
        </div>
        <span class="help-block" *ngIf="control.invalid">
            <span class="required-indicator">
                {{ getErrorMessage() }}
            </span>
        </span>
    </div>
</div>
